<template>
  <MobileHeader :mobileHeaderTitle="mobileHeaderTitle" ></MobileHeader>
  <Header />
  <Banner v-if="!isMobile"></Banner>
  <div class="container display-flex has-space has-header">
    <div class="article-popular-league__wrapper">
      <template v-if="isLoading.leftSideBar">
        <div class="display-flex-sb mb-1rem" v-for="(o, index) in 10" :key="index">
          <div class="skeleton-child skeleton-league__tick"></div>
          <div class="skeleton-child skeleton-league__name"></div>
        </div>
      </template>
      <template v-else>
        <div class="match-country__body-menu" v-for="league in top10Leagues" :key="league.id">
          <router-link :to="{ path: `/${currentLocale}/${currentSportType}/${league.countryKey}/${league.key}`, query: { leagueId: `${league.id}` }}" class="display-flex-align-center">
            <div class="icon-league mr-05rem"><img class="img-contain w-100 h-100" :src="`${s3ImgUrl}/${league.logo}`" /></div>
            <span class="match-country__body-menu__name">{{ league.name }}</span>
          </router-link>
        </div>
      </template>
    </div>
    <div class="article-content__wrapper">
      <h1>{{ theArticle.title }}</h1>
      <template v-if="!isMobile">
        <div class="article-content__ad-wrapper" v-for="banner in adBanners[currentLocale].desktop" :key="banner.img">
          <router-link 
            :to="{ path: `${banner.href}` }" 
            v-if="typeof banner.id === 'undefined'"
          >
            <img :src="banner.img" />
          </router-link>
          <!-- , query: { type: 'ongoing' } -->
          <router-link 
            :to="{ path: `${banner.href}` }"
            v-else-if="banner.id === 'Event'"
          >
            <img :src="banner.img" />
          </router-link>
          <a :id="banner.id" :href="banner.href" :target="banner.linkTarget" v-else><img :src="banner.img" /></a>
        </div>
      </template>
      <template v-else-if="isMobile">
        <div class="article-content__ad-wrapper">
          <div class="article-content__ad-slot" v-for="(banner, i) in adBanners[currentLocale].mobile" :key="i">
            <router-link :to="{ path: `${banner.href}` }" v-if="typeof banner.id === 'undefined'"><img :src="banner.img" /></router-link>
            <a :id="banner.id" :href="banner.href" :target="banner.linkTarget" v-else><img :src="banner.img" /></a>
          </div>
        </div>
      </template>
      <div class="article-content__article" v-html="theArticle.content"></div>
    </div>
    <div class="article-ads__wrapper">
      <template v-if="rightAdBanners[currentLocale]">
        <div v-for="banner in rightAdBanners[currentLocale]" :key="banner.img">
          <!-- <a :href="banner.href" :target="banner.linkTarget"><img :src="banner.img" /></a> -->
          <img :src="banner.img" />
        </div>
      </template>
    </div>
  </div>
  <Footer />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import config from '@/js/config.js';
import MobileHeader from "@/components/mobile/Header.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Banner from "@/components/Banner.vue";
import footerLinks from "@/js/seoArticles/footer.js";

export default {
  components: {
    MobileHeader,
    Header,
    Footer,
    Banner,
  },
  watch: {
    $route(to, from) {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "userInfo",
      "currentLocale",
      "currentSportType",
    ]),
  },
  data() {
    return {
      isLoading: {
        leftSideBar: true,
      },
      top10Leagues: [],
      s3ImgUrl: config.s3ImgUrl,
      theArticle: [],
      adBanners: {
        th: {
          desktop: {
            banner01: {
              img: require("../../../static/images/banner/th/desktop/article_ad_banner_01.jpg"),
              href: "/th/highlights",
            },
            banner02: {
              img: require("../../../static/images/banner/th/desktop/article_ad_banner_02.jpg"),
              href: "/th/news",
            },
            banner03: {
              img: require("../../../static/images/banner/th/desktop/article_ad_banner_03.jpg"),
              href: "/th/event",//?type=ongoing
              id: "Event",
            },
            banner04: {
              img: require("../../../static/images/banner/th/desktop/article_ad_banner_04.jpg"),
              href: config.app.android.link,
              id: "DesktopAppDownload01",
              linkTarget: "_blank",
            },
            banner05: {
              img: require("../../../static/images/banner/th/desktop/article_ad_banner_05.jpg"),
              href: config.app.apple.link,
              id: "DesktopAppDownload02",
              linkTarget: "_blank",
            },
          },
          mobile: {
            banner01: {
              img: require("../../../static/images/banner/th/mobile/article_ad_banner_01.png"),
              href: "/th/highlights",
            },
            banner02: {
              img: require("../../../static/images/banner/th/mobile/article_ad_banner_02.png"),
              href: "/th/news",
            },
            banner03: {
              img: require("../../../static/images/banner/th/mobile/article_ad_banner_03.png"),
              href: "/th/event",
              id: "Event",
            },
            banner04: {
              img: require("../../../static/images/banner/th/mobile/article_ad_banner_04.png"),
              href: "#",
              id: "AppDownloadCta",
              linkTarget: "_blank",
            },
          },
        },
        vn: {
          desktop: {
            banner01: {
              img: require("../../../static/images/banner/vn/desktop/article_ad_banner_01.jpg"),
              href: "/vn/highlights",
            },
            banner02: {
              img: require("../../../static/images/banner/vn/desktop/article_ad_banner_02.jpg"),
              href: "/vn/news",
            },
            banner03: {
              img: require("../../../static/images/banner/vn/desktop/article_ad_banner_03.jpg"),
              href: "/vn/event",
              id: "Event",
            },
            banner04: {
              img: require("../../../static/images/banner/vn/desktop/article_ad_banner_04.jpg"),
              href: config.app.android.link,
              id: "DesktopAppDownload01",
              linkTarget: "_blank",
            },
            banner05: {
              img: require("../../../static/images/banner/vn/desktop/article_ad_banner_05.jpg"),
              href: config.app.apple.link,
              id: "DesktopAppDownload02",
              linkTarget: "_blank",
            },
          },
          mobile: {
            banner01: {
              img: require("../../../static/images/banner/vn/mobile/article_ad_banner_01.png"),
              href: "/vn/highlights",
            },
            banner02: {
              img: require("../../../static/images/banner/vn/mobile/article_ad_banner_02.png"),
              href: "/vn/news",
            },
            banner03: {
              img: require("../../../static/images/banner/vn/mobile/article_ad_banner_03.png"),
              href: "/vn/event",
              id: "Event",
            },
            banner04: {
              img: require("../../../static/images/banner/vn/mobile/article_ad_banner_04.png"),
              href: "#",
              id: "AppDownloadCta",
              linkTarget: "_blank",
            },
          },
        }
      },
      rightAdBanners: {
        // th: {
        //   banner01: {
        //     img: "https://tnyurl.one/thhome",
        //     href: "/th/",
        //     linkTarget: "_self",
        //   },
        //   banner02: {
        //     img: "https://tnyurl.one/thnews",
        //     href: "/th/news",
        //     linkTarget: "_self",
        //   },
        // },
        // vn: {
        //   banner01: {
        //     img: "https://tnyurl.one/vnhome",
        //     href: "/vn",
        //     linkTarget: "_self",
        //   },
        //   banner02: {
        //     img: "https://tnyurl.one/vnnews",
        //     href: "/vn/news",
        //     linkTarget: "_self",
        //   },
        // },
      }
    };
  },
  methods: {
    ...mapActions([
      "getTop10Leagues",
    ]),
    init() {
      this.getMatchedContent();
      this.getTop10LeaguesData();
      this.getAppDownloadLink();
    },
    async getTop10LeaguesData() {
      let params = {
        lang: this.currentLocale,
      };
      const result = await this.getTop10Leagues(params);
      if (result.message == "Success") {
        this.top10Leagues = result.result;
        this.isLoading.leftSideBar = false;
      }
    },
    async getMatchedContent() {
      let pathArr = decodeURI(this.$route.path).split('/');
      let footerLinksRes = await footerLinks;
      let footerLinksLang = footerLinksRes[pathArr[1]];
      let matchedContent;
      
      if (typeof footerLinksLang !== 'undefined') {
        matchedContent = footerLinksLang.filter(article => article.url === pathArr[2])[0];
        if (matchedContent) {
          this.theArticle = matchedContent;
        } else {
          this.backToHomePage();
        }
      } else {
        this.backToHomePage();
      }
    },
    getAppDownloadLink() {
      if (this.isMobile) {
        const appDownloadCta = document.querySelector("#AppDownloadCta");
        if (appDownloadCta !== null) {
          const mobileOS = this.$tools.getMobileOperatingSystem();
          if (mobileOS.indexOf('iOS') === -1) {
            appDownloadCta.href = config.app.android.link;
          } else {
            appDownloadCta.href = config.app.apple.link;
          }
        }
      }
    },
    backToHomePage() {
      this.$router.push({ name: 'home' });
    },
  },
}
</script>

<style>
.article-popular-league__wrapper {
  width: 13rem;
}
.article-content__wrapper {
  width: 35rem;
  margin: 0 1rem 1rem;
  color: #FFFFFF;
}
.article-ads__wrapper {
  width: 24rem;
  margin-left: 1rem;
}

.article-popular-league__wrapper .match-country__body-menu {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  cursor: pointer;
  margin-bottom: 0.3rem;
}
.article-popular-league__wrapper  .match-country__body-menu__name {
  width: 10rem;/*7.5rem*/
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.article-content__wrapper h1 {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}
.article-content__ad-wrapper {
  margin: 0.25rem 0;
}
.article-content__ad-wrapper a img {
  width: 100%;
  border-radius: 1rem;
}
.article-content__article {
  margin-top: 1.5rem;
}
.article-content__article p {
  margin-bottom: 1.8rem;
}
.article-content__article  a {
  text-decoration: underline;
}
.article-content__article ul {
  margin: 0 0 1rem 1.5rem;
}
.article-content__article li::marker {
  content: initial;
}
.article-ads__wrapper img {
  width: 20.75rem;
  height: 16.125rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .article-popular-league__wrapper,
  .article-ads__wrapper {
    display: none;
  }
  .article-content__wrapper {
    width: calc(100vw - 1.6rem);
    margin: 0 0 1rem;
  }
  .article-content__ad-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .article-content__ad-wrapper .article-content__ad-slot {
    width: calc(50% - 0.2rem);
    padding-bottom: 0.1rem;
  }
  .article-content__ad-wrapper .article-content__ad-slot:nth-child(odd) {
    padding-right: 0.4rem;
  }
  .article-content__ad-wrapper a img {
    border-radius: 0;
  }
}
</style>
