<template>
  <div id="home-banner" class=" home-banner__wrapper">
    <div v-if="isLoading" class="skeleton-child h-100"></div>
    <div v-else class="h-100">
      <template v-if="bannerList.length>0">
      <swiper
        class="relative sport168"
        :pagination="{ clickable: true }"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
      >
        <div class="home-banner__layer"></div>
        <swiper-slide
          v-for="(o, index) in bannerList"
          :key="index"
          @click="redirectTo(o)"
        >
          <div class="home-banner__container">
            <!-- <div class="home-banner__blur"></div> -->
            <!-- <img class="home-banner__img" v-bind:src="require(`../../static/images/banner/${currentLocale}/${currentDevice}/home_banner_${o-1}.jpg`)">   -->
            <img
              class="home-banner__img"
              :alt="o.image"
              v-bind:src="`${s3ImgUrl}/${o.image}`"
            />
          </div>
        </swiper-slide>
      </swiper>
      </template>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Navigation, Autoplay]);

import config from "@/js/config.js";

import { mapGetters, mapActions } from "vuex";

// const DESKTOP = 'desktop'
// const MOBILE = 'mobile'
export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      // currentDevice: 'desktop',
      // totalBanner: 3,
      bannerList: [],
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.bannerInit();
    });
  },
  computed: {
    ...mapGetters(["isMobile", "currentLanguageObj", "currentSportType"]),
  },
  watch: {
    $route() {
      this.bannerInit();
    },
  },
  methods: {
    ...mapActions(["getFocusPictureFindPicture"]),
    bannerInit() {
      this.getFocusPictureFindPictureData();

      // if (this.currentLocale === 'vn') {
      //     this.totalBanner = 3;
      // } else {
      //     this.totalBanner = 4;
      // }

      // if (this.isMobile) {
      //     this.currentDevice = MOBILE;
      // } else {
      //     this.currentDevice = DESKTOP;
      // }
    },
    async getFocusPictureFindPictureData() {
      this.isLoading = true;
      let params = {
        deviceType: this.isMobile ? 1 : 0, //0=web; 1=webapp
        imageLang: this.currentLanguageObj.locale,
        imageType: 0, //0= banner; 1=logo,
        sportType: this.currentSportType === 'football' ? 0 : 1
      };

      const result = await this.getFocusPictureFindPicture(params);
      this.bannerList = result.result;
      this.isLoading = false;
    },

    redirectTo(o) {
      let url = o.link;
      let urlHttp = o.link.includes("http");

      if (url) {
        if (o.openWith) {
          //0:existing window, 1:new window
          window.open(urlHttp ? url : "https://" + url);
          // window.location.href = url;
        } else {
          window.location.href = urlHttp ? url : "https://" + url;
        }
      } else {
        return;
      }
    },
  },
};
</script>

<style>
.skeleton-banner__wrapper {
  width: 100%;
  height: 100%;
  background-color: #212b30;
  padding: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
}
.skeleton-banner__container {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #989898 25%, #adb7bc 37%, #a2a2a2 63%);
  border-radius: 0.2rem;
  background-size: 400% 100%;
  -webkit-animation: el-skeleton-loading 1.4s ease infinite;
  animation: el-skeleton-loading 1.4s ease infinite;
}
.home-banner__wrapper {
  overflow: hidden;
  width: auto;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
}
.home-banner__img {
  object-fit: cover;
}
.home-banner__layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: var(--bg-theme);
  opacity: 0.15;
  z-index: 2;
  pointer-events: none;
}
.home-banner__blur {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 1.5rem;
  pointer-events: none;
  background-image: linear-gradient(to bottom, transparent, var(--bg-theme));
  z-index: 2;
}
.home-banner__blur-top {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 1.5rem;
  background-image: linear-gradient(to top, transparent, var(--bg-theme));
  z-index: 2;
}
@media (min-width: 768px) {
  .home-banner__wrapper {
    /* height: 5.625rem; */
    width: 1200px;
    margin-bottom: 3.375rem;
  }
  .home-banner__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
